import { Injectable } from '@angular/core';
import { IApplicationDictionaryService } from '@common/services/iapplication-dictionary-service';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ApplicationDictionaryService
  implements IApplicationDictionaryService
{
  public Training: string;

  constructor() {
    this.Training = 'treatment';
  }

  public getTextByTemplate(
    template: string,
    token: string,
    value: string,
  ): string {
    if (_.isNil(template) || _.isNil(token) || _.isNil(value)) return template;
    return template.replace(token, value);
  }
}
