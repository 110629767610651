import {
  IApplicationPathsType, IStringConstants,
  IColorConstants,
} from '@common/co/core/app.constants';

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};

let applicationPaths: IApplicationPathsType = {
  DefaultLoginRedirectPath: '/',
  DefaultRegisterRedirectPath: '/',
  Login: `${LoginActions.Login}`,
  LoginFailed: `${LoginActions.LoginFailed}`,
  LoginCallback: `${LoginActions.LoginCallback}`,
  Register: `${LoginActions.Register}`,
  Profile: `${LoginActions.Profile}`,
  LogOut: `${LogoutActions.Logout}`,
  LoggedOut: `${LogoutActions.LoggedOut}`,
  LogOutCallback: `${LogoutActions.LogoutCallback}`,
  LoginPathComponents: [],
  LoginFailedPathComponents: [],
  LoginCallbackPathComponents: [],
  RegisterPathComponents: [],
  ProfilePathComponents: [],
  LogOutPathComponents: [],
  LoggedOutPathComponents: [],
  LogOutCallbackPathComponents: [],
  IdentityRegisterPath: '/Identity/Account/Register',
  IdentityManagePath: '/Identity/Account/Manage',
};

applicationPaths = {
  ...applicationPaths,
  LoginPathComponents: applicationPaths.Login.split('/'),
  LoginFailedPathComponents: applicationPaths.LoginFailed.split('/'),
  RegisterPathComponents: applicationPaths.Register.split('/'),
  ProfilePathComponents: applicationPaths.Profile.split('/'),
  LogOutPathComponents: applicationPaths.LogOut.split('/'),
  LoggedOutPathComponents: applicationPaths.LoggedOut.split('/'),
  LogOutCallbackPathComponents: applicationPaths.LogOutCallback.split('/'),
};

export const ApplicationPaths: IApplicationPathsType = applicationPaths;

export const StringConstants: IStringConstants = {
  InStudio: 'In Clinic',
  OutOfStudio: 'At Home'
}

export const colorConstants: IColorConstants = {
  best: '#FF6A00',
  score: '#A3A3A3',
  avg: '#007BFF',
  radarChartColor: 'rgba(0,123,255, 0.7)',
  radarChartFontColor: 'rgba(30, 30, 35, 1)',
  graphColorHigh: '#FF6A00',
  graphColorAverages: '#363636',
  graphColorLow: '#007BFF',
};
