import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  AfterContentInit,
  NgZone,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Device, DeviceInfo } from '@capacitor/device';

import { FuseConfigService } from '@fuse/services/config.service';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import { SplSplashScreenService } from '@common/co/core/services/splash-screen.service';
import { PlatformService } from '@common/co/core/services/platform.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import _ from 'lodash';
import { TrainingState } from '@common/co/shared/models/training-state.model';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import {
  AppSettingsClient,
  AppSettingsResponse,
  AthleteClient,
  LastVisitDateResponse,
} from '@common/services/co-api-client';
import { FuseNavigation } from '@fuse/types';
// import { App } from '@capacitor/app';

// App.addListener('appStateChange', ({ isActive }) => {
//   console.log('App state changed. Is active?', isActive);
// });

// App.addListener('appUrlOpen', data => {
//   console.log('App opened with URL:', data);
// });

// App.addListener('appRestoredResult', data => {
//   console.log('Restored state:', data);
// });

// const checkAppLaunchUrl = async () => {
//   const { url } = await App.getLaunchUrl();

//   alert('App opened with URL: ' + url);
// };

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  fuseConfig: any;
  navigation: FuseNavigation[];
  isAuthenticated$: Observable<boolean>;
  isAuthenticated: boolean;
  isInitialized: boolean = false;
  inTakeService: InTakeService;

  // Private
  private _unsubscribeAll: Subject<void>;
  private domainRegExp = /^(?:https?:\/\/)?([^/]+)/gi;

  private lastVisitPromise: Promise<LastVisitDateResponse>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {SplNavigationService} _splNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {SplSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _fuseConfigService: FuseConfigService,
    private _splNavigationService: SplNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: SplSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _authorizeService: AuthorizeService,
    private _router: Router,
    private renderer: Renderer2,
    inTakeService: InTakeService,
    private platformService: PlatformService,
    private zone: NgZone,
    private _appBusService: AppBusService,
    private _appSettingsClient: AppSettingsClient,
    private _apthleteClient: AthleteClient,
  ) {
    this.isAuthenticated$ = this._authorizeService.isAuthenticated();
    // Get default navigation
    this.navigation = navigation;
    this.inTakeService = inTakeService;
    // Register the navigation to the service
    this._splNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._splNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationTurkish,
    );

    // Use a language
    this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this.platformService.isMobile) {
      this.document.body.classList.add('is-mobile');
    } else {
      this.document.body.classList.add('is-desktop');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.initDeepLinksListener();

    this._appBusService.trainingStateChanged$.subscribe(
      (state: TrainingState) => {
        if (this.platformService.isMobile) {
          switch (state) {
            case TrainingState.InProgress:
              this._fuseConfigService.setToolbarHidden(true);
              break;
            default:
              this._fuseConfigService.setToolbarHidden(false);
          }
        }
      },
    );
  }

  ngAfterContentInit(): void {
    this._authorizeService.initialized$.subscribe(() => {
      setTimeout(() => {
        this.platformService.hideNativeSplashScreen();
      }, 1500);
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    Device.getInfo().then((info: DeviceInfo) => {
      const platform = info.platform;
      const model = (info.model || '').replace(/\s/g, '');
      const osVersion = (info.osVersion || '').replace(/\s/g, '');
      const root = document.firstElementChild;
      this.renderer.addClass(root, platform);
      this.renderer.addClass(root, model);
      this.renderer.addClass(root, osVersion);
    });

    this._authorizeService.initialized$.subscribe(
      (isInitialized) => (this.isInitialized = isInitialized),
    );

    await this.initializeApp();

    console.log('Register custom capacitor plugins');

    // const accessTokeExpired = await this._authorizeService.isAccessTokenExpired();

    // await this.handleInit(!accessTokeExpired);

    this._appBusService.loginData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((info) => {
        if (!info.profile) return;
        if (!this.lastVisitPromise) {
          this.lastVisitPromise = this._apthleteClient
            .updateLastVisitDate()
            .toPromise();

          this.lastVisitPromise.then((r) => {
            this.lastVisitPromise = null;
            if (r.succeeded)
              console.log('last visit date updated: ' + r.lastVisitDate);
          });
        }
      });

    this._appBusService.setProfile$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profile) => {
        if (!profile) return;
        if (!this.lastVisitPromise) {
          this.lastVisitPromise = this._apthleteClient
            .updateLastVisitDate()
            .toPromise();

          this.lastVisitPromise.then((r) => {
            this.lastVisitPromise = null;
            if (r.succeeded)
              console.log('last visit date updated: ' + r.lastVisitDate);
          });
        }
      });

    this._authorizeService
      .isAuthenticated()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((authenticated) => {
        this.isAuthenticated = authenticated;
      });

    // load settings only if user passed intake
    this._appBusService.inTakePassed$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((inTakePassed: boolean) => {
        if (inTakePassed) {
          this._appSettingsClient
            .getAppSettings()
            .pipe(first())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: AppSettingsResponse) => {
              this._appBusService.setAppSettings(result);
            });
        }
      });

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    return Promise.resolve();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Remove the custom function menu
    this._splNavigationService.removeNavigationItem('custom-function');
  }

  private initDeepLinksListener(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domainMatch = event.url.match(this.domainRegExp);
        const domain = _.first(domainMatch);
        if (domain) {
          const path = event.url.split(domain).pop();
          if (path) {
            this._router.navigateByUrl(path);
          }
        }
      });
    });
  }

  public async initializeApp(): Promise<void> {
    console.debug('AppComponent => initializeApp');

    // const isAuthenticated = await this._authorizeService.isAuthenticated().toPromise();
    // const isTokenExpired = this._authorizeService.isAccessTokenExpired();

    // if (isAuthenticated && !isTokenExpired) {
    //   console.debug('AppComponent => logged in and token not expired');
    // } else if (isAuthenticated && isTokenExpired) {
    //   console.debug('AppComponent => already logged in, expired token');
    //   await this._authorizeService.fetchNewAccessToken();
    // } else {
    //   console.debug('AppCOmponent => not logged in');
    // }
    return Promise.resolve();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // /**
  //  * Toggle sidebar open
  //  *
  //  * @param key
  //  */
  // private toggleSidebarOpen(key): void {
  //   this._fuseSidebarService.getSidebar(key).toggleOpen();
  // }

  // private async handleInit(isAuthenticated: boolean): Promise<void> {
  //   if (!isAuthenticated) {
  //     await this._router.navigate(ApplicationPaths.LoginPathComponents);
  //   } else {
  //     await this._router.navigateByUrl('apps/dashboards/analytics', {
  //       replaceUrl: true
  //     });
  //   }
  // }
}
