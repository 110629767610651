import { FuseNavigation } from '@fuse/types';
import {
  commonNavigationConfig,
  INavigationConfig,
} from '@common/co/navigation/navigation';
import { StatisticsActivities } from '@common/models/statistic-activities';

export const navigationConfig: INavigationConfig = {
  root: {
    id: 'root',
    title: 'Root',
    type: 'item',
    url: '',
    hidden: true,
  },
  register: {
    id: 'register',
    title: 'Register',
    type: 'item',
    url: '/register',
  },
  check_unique_id: {
    id: 'check_unique_id',
    title: 'Check Unique Id',
    type: 'item',
    url: '/check-unique-id',
  },
  forgot_password: {
    id: 'forgot_password',
    title: 'Forgot Password',
    type: 'item',
    url: '/forgot-password',
  },
  treatment: {
    id: 'treatment',
    title: 'Treatment',
    type: 'item',
    url: '/training',
    payload: {
      breadcrumb: 'Treatment Overview',
    },
    groupType: 'general',
  },
  edit_profile: {
    id: 'edit_profile',
    title: 'Edit Profile',
    type: 'item',
    url: undefined,
    isActionable: true,
    groupType: 'profile',
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_EDIT_PROFILE,
    },
  },
  sign_out: {
    id: 'sign_out',
    title: 'Sign out',
    type: 'item',
    url: undefined,
    isActionable: true,
    groupType: 'profile',
  },
  about_spl: {
    id: 'about_spl',
    title: 'About QFNA',
    type: 'item',
    url: '/about',
    isActionable: true,
    groupType: 'profile',
    payload: {
      breadcrumb: 'About QFNA',
      pageTrackingKey: StatisticsActivities.PAGE_ABOUT,
    },
  },
  select_profile: {
    id: 'select_profile',
    title: 'Select Profile',
    type: 'item',
    url: '/select-profile?',
    hidden: true,
  },
  schedule_in_studio_single_session: {
    id: 'schedule_in_studio_single_session',
    title: 'Schedule In-Studio Training',
    type: 'item',
    url: '/training/in-studio-single-session',
    payload: {
      breadcrumb: 'Schedule In-Studio Training',
    },
    hidden: true,
  },
};

export const navigation: FuseNavigation[] = [
  commonNavigationConfig.dashboard,
  commonNavigationConfig.testing_assessment,
  navigationConfig.treatment,
  navigationConfig.edit_profile,
  commonNavigationConfig.preferences,
  commonNavigationConfig.change_password,
  commonNavigationConfig.switch_profile,
  navigationConfig.sign_out,
  navigationConfig.about_spl,
];
